<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Que puis-je partager sur knowledge.scor.com ?
                p.content(v-if="toggleQuestions[0] === true")
                    | Vous pouvez partager tout contenu : un sujet sur lequel vous travaillez, un lien externe, une information, tout ce qui est utile pour votre travail et en tant que tel pourrait être utile à l'un de vos collègues à tout moment dans le futur.
                    br
                    | L'outil est accessible à toute personne au sein du réseau SCOR. En tant que tel, assurez-vous que les informations que vous partagez peuvent être diffusées en interne.
                    br
                    | Lors de votre contribution, veuillez ne pas inclure d'informations relatives aux personnes (données personnelles) si cela n'est pas strictement nécessaire à la finalité ci-dessus. En tout état de cause, aucune donnée personnelle sensible - telle que des données relatives à la santé, à la race, à l'origine ethnique, aux opinions politiques, aux convictions religieuses, philosophiques - ne doit être partagée sur knowledge.scor.com


        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 What can I share on knowledge.scor.com ?
                p.content(v-if="toggleQuestions[0] === true")
                    | You can share anything, a topic you worked on, an external link, an information, anything that is useful for your work and as such could be useful to any of your colleagues anytime in the future.
                    br
                    | The tool is available to anyone within SCOR’s network. As such, make sure the information that you share is ok to be shared internally.
                    br
                    | When contributing, please do not include any information relating to individuals (personal data) if this is not strictly necessary for the above purpose.  In any case, no sensitive personal data - such as data relating to health, race, ethnic origin, political opinions, religious, philosophical beliefs - should be shared on knowledge.scor.com.


        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 我在knowledge.scor.com可以分享什么？
                p.content(v-if="toggleQuestions[0] === true")
                    | 你可以分享任何东西：你工作的主题，外部链接，一个信息，任何对你的工作有用的东西，这样，在以后可能对你的同事们有帮助。
                    br
                    | SCOR网络中的任何人都可以使用该工具。因此，要确保你所分享的信息可以在内部共享。
                    br
                    | 在发布时，如果不是出于上述目的的严格需要，请不要包括有关个人的信息（个人数据）。在任何情况下，都不应将敏感的个人数据（如与健康、种族、民族血统、政治观点、宗教或哲学信仰有关的数据）在knowledge.scor.com上分享。

        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Quelle est la politique concernant les données ?
                div.content(v-if="toggleQuestions[1] === true")
                    p.text-averta-light-38 Données personnelles – knowledge.scor.com
                    p.subtitle-regular-18 Le site knowledge.scor.com (ci-après le « Site ») géré par SCOR conduit à un traitement de données personnelles.
                    p.text-averta-light-32 Objet du traitement de données
                    p.text-averta-semibold-20 Finalités
                    p.subtitle-regular-18 Le traitement a pour objet de permettre aux collaborateurs de SCOR le partage et la diffusion d’informations, de connaissances et de bonnes pratiques (savoirs, savoir-être, savoir-faire) afin de contribuer à l’amélioration globale de leurs compétences.
                    p.subtitle-regular-18 Il permet à SCOR de développer le partage de la connaissance et du savoir au sein de groupes de collaborateurs désignés, en permettant d’échanger et de décloisonner et centraliser sur un site les connaissances des collaborateurs sur leurs domaines d’expertise.
                    p.text-averta-semibold-20 Base légale
                    p.subtitle-regular-18 Ce traitement de données relève de l'intérêt légitime de SCOR pour développer le partage de la connaissance et du savoir au sein du Groupe.
                    p.text-averta-light-32 Données traitées
                    p.text-averta-semibold-20 Catégories de données traitées
                    ul
                        li.subtitle-regular-18 •	Données d’identification des collaborateurs utilisateurs du Site (nom, prénom, adresse électronique professionnelle, localisation, numéro de téléphone, compétences, avatar, contenu partagé)
                        li.subtitle-regular-18 •	Données d’utilisation du service (dates et heures de connexion sur le site)
                    p.text-averta-semibold-20 Source des données
                    p.subtitle-regular-18 Ces informations sont recueillies par le collaborateur lorsqu’il utilise et met à jour ses informations sur le Site.
                    p.text-averta-semibold-20 Caractère obligatoire du recueil des données
                    p.subtitle-regular-18 Ce traitement prévoit le recueil obligatoire des données qui sont nécessaires pour l’utilisation du Site, l’utilisation du Site n’étant pas elle-même obligatoire.
                    p.text-averta-semibold-20 Prise de décision automatisée
                    p.subtitle-regular-18 Le traitement ne prévoit pas de prise de décision automatisée.
                    p.text-averta-light-32 Personnes concernées
                    p.subtitle-regular-18 Le traitement de données concerne uniquement les collaborateurs qui souhaitent utiliser le Site pour le partage et la diffusion d’informations, de connaissances et de bonnes pratiques.
                    p.text-averta-light-32 Destinataires des données
                    p.text-averta-semibold-20 Catégories de destinataires
                    p.subtitle-regular-18 Sont destinataires des données personnelles :
                    ul
                        li.subtitle-regular-18 •	le personnel en charge du Knowledge Management de SCOR
                        li.subtitle-regular-18 •	le prestataire fournisseur du service de mise à disposition de la plateforme collaborative en mode « Software as a Service » (SaaS) :
                        li
                            ul
                                li &emsp;&emsp;Wats
                                li &emsp;&emsp;85 boulevard Pasteur
                                li &emsp;&emsp;75015 Pairs
                                li &emsp;&emsp;France
                        li.subtitle-regular-18 •	L’hébergeur de la plateforme situé dans l’Unions européenne : Microsoft Azure
                    p.text-averta-semibold-20 Transferts des données hors UE
                    p.subtitle-regular-18 Aucun transfert de données hors de l'Union européenne n'est réalisé.
                    p.text-averta-light-32 Durée de conservation des données
                    p.subtitle-regular-18 Les données sont collectées à partir du moment où le collaborateur s’enregistre sur le Sire pour le partage et la diffusion d’informations, de connaissances et de bonnes pratiques jusqu’à la fin de la relation contractuelle entre SCOR et le prestataire.
                    p.subtitle-regular-18 Les données peuvent également être supprimées du Site à la demande du collaborateur.
                    p.text-averta-light-32 Sécurité
                    p.subtitle-regular-18 Les mesures de sécurité suivantes sont mises en œuvre pour assurer la sécurité de données personnelles et des échanges :
                    ul
                        li.subtitle-regular-18 •	Connexion et hébergement sécurisés
                        li.subtitle-regular-18 •	Accès au Site via la méthode de Single Sign On (SSO)
                    p.text-averta-light-32 Contenu partagé
                    p.subtitle-regular-18 Lorsque vous contribuez, veuillez ne pas inclure d'informations relatives aux individus (données personnelles) si cela n'est pas strictement nécessaire pour l'objectif ci-dessus.  En tout état de cause, aucune donnée personnelle sensible - telle que des données relatives à la santé, la race, l'origine ethnique, les opinions politiques, les croyances religieuses ou philosophiques - ne doit être partagée sur knowledge.scor.com.
                    p.text-averta-light-32 Vos droits sur les données vous concernant
                    p.subtitle-regular-18 Pour plus de détails concernant l’exercice de vos droits sur les données vous concernant, veuilles consulter la Politique de confidentialité du Groupe SCOR.
        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 What's the Data Privacy Policy ?
                div.content(v-if="toggleQuestions[1] === true")
                    p.text-averta-light-38 Personal data – knowledge.scor.com
                    p.subtitle-regular-18 The knowledge.scor.com site (hereinafter the “Site”) managed by SCOR leads to the processing of personal data.
                    p.text-averta-light-32 Purpose and legal basis
                    p.text-averta-semibold-20 Purpose
                    p.subtitle-regular-18 The purpose of the processing is to enable SCOR employees to share and disseminate information, knowledge and best practices (knowledge, soft skills, know-how) in order to contribute to the overall improvement of their skills.
                    p.subtitle-regular-18 It allows SCOR to develop the sharing of knowledge and know-how within groups of designated employees, to foster the exchange, sharing and centralization of the knowledge of employees in their areas of expertise on one site.
                    p.text-averta-semibold-20 Legal basis
                    p.subtitle-regular-18 This legal basis of the processing is the legitimate interest of SCOR to develop the sharing of knowledge and know-how within the Group.
                    p.text-averta-light-32 Processed data
                    p.text-averta-semibold-20 Categories of processed data
                    ul
                        li.subtitle-regular-18 •	Identification data of employees who use the Site (surname, first name, professional email address, location, phone number, job title, avatar, skills, content shared)
                        li.subtitle-regular-18 •	Service usage data (dates, times of connection to the site)
                    p.text-averta-semibold-20 Source of data
                    p.subtitle-regular-18 The data is provided and updated by the employee when he/she uses the Site.
                    p.text-averta-semibold-20 Compulsory nature of data collection
                    p.subtitle-regular-18 The processing provides for the compulsory collection of data which is necessary for the use of the Site.  However, the use of the Site is not compulsory.
                    p.text-averta-semibold-20 Automated decision making
                    p.subtitle-regular-18 The processing does not involve any automated decision-making.
                    p.text-averta-light-32 Data subjects
                    p.subtitle-regular-18 The persons subject to personal data processing are exclusively employees who wish to use the Site for the sharing and dissemination of information, knowledge and best practices.
                    p.text-averta-light-32 Data recipients
                    p.text-averta-semibold-20 Categories of recipients
                    p.subtitle-regular-18 The recipients of personal data are:
                    ul
                        li.subtitle-regular-18 •	the personnel in charge of SCOR's Knowledge Management
                        li.subtitle-regular-18 •	the service provider providing the collaborative platform in “Software as a Service” (SaaS) mode:
                        li
                            ul
                                li &emsp;&emsp;Wats
                                li &emsp;&emsp;85 boulevard Pasteur
                                li &emsp;&emsp;75015 Pairs
                                li &emsp;&emsp;France
                        li.subtitle-regular-18 •	The platform host located in the European Union: Microsoft Azure
                    p.text-averta-semibold-20 Transfers of data outside the EU
                    p.subtitle-regular-18 No data is transferred outside the European Union.
                    p.text-averta-light-32 Data retention period
                    p.subtitle-regular-18 The data is collected from the moment the employee registers on the Site for the sharing and dissemination of information, knowledge and best practices until the end of the contractual relationship between SCOR and the service provider.
                    p.subtitle-regular-18 The data can also be deleted from the Site upon request of the employee.
                    p.text-averta-light-32 Security
                    p.subtitle-regular-18 The following security measures are in place to ensure the security of personal data and exchanges:
                    ul
                        li.subtitle-regular-18 •	Secured connection and hosting
                        li.subtitle-regular-18 •	Access to the Site via the Single Sign On (SSO) method
                    p.text-averta-light-32 Content shared
                    p.subtitle-regular-18 When contributing, please do not include any information relating to individuals (personal data) if this is not strictly necessary for the above purpose.  In any case, no sensitive personal data - such as data relating to health, race, ethnic origin, political opinions, religious, philosophical beliefs - should be shared on knowledge.scor.com.
                    p.text-averta-light-32 Your data subject’s rights
                    p.subtitle-regular-18 For more details concerning the exercise of your rights as data subject, please consult the SCOR Group Privacy Policy.
        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 什么是数据隐私政策？
                div.content(v-if="toggleQuestions[1] === true")
                    p.text-averta-light-38 个人数据——knowledge.scor.com
                    p.subtitle-regular-18 SCOR 管理的 Knowledge.scor.com 网站（以下简称“网站”）负责处理个人数据。
                    p.text-averta-light-32 目的和法律依据
                    p.text-averta-semibold-20 目的
                    p.subtitle-regular-18 处理的目的是使 SCOR 员工能够共享和传播信息、知识和最佳实践（知识、软技能、专有技术），以促进其技能的整体提高。
                    p.subtitle-regular-18 它使 SCOR 能够在指定员工组内发展知识和专有技术的共享，以促进员工在其专业领域内的知识交流、共享和集中在一个站点上。
                    p.text-averta-semibold-20 法律基础
                    p.subtitle-regular-18 这种处理的法律依据是 SCOR 在集团内发展知识和专有技术共享的合法利益。
                    p.text-averta-light-32 数据处理
                    p.text-averta-semibold-20 数据处理类别
                    ul
                        li.subtitle-regular-18 •	使用本网站的员工的身份数据（姓氏、名字、专业电子邮件地址、位置、电话号码、职位、头像、技能、共享内容）
                        li.subtitle-regular-18 •	服务使用数据（日期、网站连接时间）
                    p.text-averta-semibold-20 数据来源
                    p.subtitle-regular-18 数据由员工在使用本网站时提供和更新。
                    p.text-averta-semibold-20 数据收集的强制性
                    p.subtitle-regular-18 该处理规定强制收集使用本网站所必需的数据。但是，使用本网站不是强制性的。
                    p.text-averta-semibold-20 自动决策
                    p.subtitle-regular-18 该处理不涉及任何自动决策。
                    p.text-averta-light-32 数据主体
                    p.subtitle-regular-18 接受个人数据处理的人是专门希望使用本网站来共享和传播信息、知识和最佳实践的员工。
                    p.text-averta-light-32 数据接收对象
                    p.text-averta-semibold-20 收件人类别
                    p.subtitle-regular-18 个人数据的接收人有：
                    ul
                        li.subtitle-regular-18 •	SCOR 知识管理负责人
                        li.subtitle-regular-18 •	以“软件即服务”（SaaS）模式提供协作平台的服务提供商：
                        li
                            ul
                                li &emsp;&emsp;Wats
                                li &emsp;&emsp;85 boulevard Pasteur
                                li &emsp;&emsp;75015 Pairs
                                li &emsp;&emsp;France
                        li.subtitle-regular-18 •	位于欧盟的平台主机：Microsoft Azure
                    p.text-averta-semibold-20 欧盟以外的数据传输
                    p.subtitle-regular-18 没有数据传输到欧盟以外。
                    p.text-averta-light-32 数据保留期
                    p.subtitle-regular-18 从员工在网站上注册以共享和传播信息、知识和最佳实践的那一刻起收集数据，直到 SCOR 与服务提供商之间的合同关系结束。
                    p.subtitle-regular-18 也可以根据员工的要求从网站上删除数据。
                    p.text-averta-light-32 安全
                    p.subtitle-regular-18 采取以下安全措施来确保个人数据和交换的安全：
                    ul
                        li.subtitle-regular-18 •	安全连接和托管
                        li.subtitle-regular-18 •	通过单点登录 (SSO) 方法访问网站
                    p.text-averta-light-32 分享的内容
                    p.subtitle-regular-18 在投稿时，如果上述目的并非绝对必要，请不要包含任何与个人有关的信息（个人数据）。在任何情况下，任何敏感的个人数据——例如与健康、种族、民族血统、政治观点、宗教、哲学信仰有关的数据——都不应在 Knowledge.scor.com 上共享。
                    p.text-averta-light-32 您的数据主体的权利
                    p.subtitle-regular-18 有关行使您作为数据主体的权利的更多详细信息，请参阅 SCOR 集团隐私政策。
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({

    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }

})
</script>

<style lang="scss" scoped>
@import './question.scss';

.content {
    line-height: 1.5;

    .text-averta-light-38 {
        margin-bottom: 10px;
    }

    .text-averta-light-32 {
        margin: 10px 0;
    }

    .content {
        .text-averta-semibold-20 {
            margin: 8px 0;
        }
    }

}
</style>